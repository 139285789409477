/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-10-22T16:40:59.495Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.4.10100-1594380';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
